<script>
import InputValidatorsComponent from '../../../../shared-component/input-validators-component/input-validators-component.vue'
import CheckboxComponent from '../../components/checkbox-component/checkbox-component.vue'
import {FormValidatorsMixin} from '../../../../../mixins/form-validators-mixin.js'
import Const from "../../../../../model/Const.js"

export default {
    name: "NewAdPage3",
    components: {
        InputValidatorsComponent,
        CheckboxComponent
    },
    mixins: [FormValidatorsMixin],
    props: ["produitPhysique",'templateMode'],
    data() {
        return {
            optionsYesNo:[
                {value: null, text: 'Veuillez choisir...', disabled: true},
                {value: true, text: 'Oui', disabled: false},
                {value: false, text: 'Non', disabled: false}],
            form: {
               
                invoice: null,
                guarantee: null,
                envoipossible:null,
                condition: null,
            },
           
            isActive: false,
            invoiceSet:false,
            envoiSet:false,
            guaranteeSet:false,
            conditionSet:false,
            
             options_condition: [
                { value: null, text: 'Etat du produit' , disabled: true},
                ...Const.getEtatsProduits()
            ],
        }
    },
    created()  {

    },
    methods: {
        factureChanged(){
            console.info("factureChanged to " + this.form.invoice);
             this.invoiceSet = true;
             this.updateContinuerButton();
        },
        guarantyChanged(){
            console.info("guarantyChanged to " + this.form.guarantee);
             this.guaranteeSet = true;
            this.updateContinuerButton();
        },
        envoiChanged(){
            console.info("envoiChanged");
            this.envoiSet = true;
            this.updateContinuerButton();
        },

        conditionHasBeenSelected(){
            console.info("Condition has been selected ");
            this.conditionSet = true;
            this.updateContinuerButton();
        },
        
        continuer(){
            console.info("Page suivante !");
           
            this.$emit('pageDone', 3)
        },

        

        updateContinuerButton(){
           if (this.produitPhysique){
                this.isActive = this.guaranteeSet && this.invoiceSet && this.envoiSet && this.conditionSet;
           } else {
                this.isActive = this.guaranteeSet && this.invoiceSet && this.envoiSet;
           }
           
        },

        gePageStyle(){
            if (this.templateMode < 3){
                return "max-width:500px; margin: 0 auto;position:relative;";
            } else {
                return "";
            }
        }
    }
};
</script>

<style lang="scss">
@import "page3.scss";
</style>
<template src="./page3.html"></template>