import BrandList from '../assets/csv/marques/brands.csv'

export class Options {
    value;
    text;
    constructor(k, n) {
        this.value = k;
        this.text = n;
    }
}

export const BrandSortMixin = {
    data() {
        return {
            brand_options: [],
            all_brands: {}
        }
    },
    methods: {
        SelectedCategoryForBrand(category) {
            // Reset brand options when a category is selected && v-model value to null & push default disabled value
            this.brand_options = []
            this.form.brand = null
            this.brand_options.push({value: null, text: 'Sélectionnez une marque', disabled: true})

            for (const brand in BrandList) {
                this.all_brands[BrandList[brand].key] = this.all_brands[BrandList[brand].marque];
                if(BrandList[brand].categorie == category) {
                    this.brand_options.push({
                        value: BrandList[brand].key,
                        text: BrandList[brand].marque
                    })
                }
            }
        },

        getMarqueDisplayName(key){
            return this.all_brands[key];
        }
    }
}