<script>
import InputValidatorsComponent from '../../../../shared-component/input-validators-component/input-validators-component.vue'
import CheckboxComponent from '../../components/checkbox-component/checkbox-component.vue'
import {FormValidatorsMixin} from '../../../../../mixins/form-validators-mixin.js'
import {RegionDptSortMixin} from '../../../../../mixins/region-dpt-sort-mixin.js'
import {CategoryMixin} from '../../../../../mixins/category-mixin.js'

export default {
    name: "NewAdPage4",
    props: ['globalForm','templateMode'],
    components: {
        InputValidatorsComponent,
        CheckboxComponent
    },
    mixins: [FormValidatorsMixin, RegionDptSortMixin, CategoryMixin],
    data() {
        return {
            isActive: true,
            imagesBytes: [undefined,undefined,undefined,undefined],
            imagesPath: [undefined,undefined,undefined,undefined],
            form: {
               
            },
            // Form input field, valid or not
            formValid: {
               
            },
            // Not show ratio & brand for 'DVD' and 'Service'
        }
    },
    created()  {
        // Init CSV logic (Code in mixin)
        this.initRegionCSVTab()
    },
    methods: {
        continuer(){
            console.info("Click sur continuer !");
             this.$emit('pageDone', 4)
        },
        buttonClicked(index){
            console.info("buttonClicked : loading image of index " + index);
            this.$refs["imageInput"+index].click();
        },
        imageLoaded(e,imageNumber){
            console.log('image of index '+imageNumber+' loaded : ' + e); 

            let loadedCount = e.target['files'].length;
            let currentImageNumber = imageNumber;
            for(let i = 0; i < loadedCount ; i++){
                let currentImageFile = e.target['files'][i];

                this.$set(this.imagesBytes, currentImageNumber, URL.createObjectURL(currentImageFile));
                this.$set(this.imagesPath, currentImageNumber, currentImageFile);

                currentImageNumber = (currentImageNumber + 1)%4;
            }

            
        },
        isImageLoaded(index){
            return this.imagesBytes[index] != undefined;
        },
        deletePhoto(index){
            console.info("deletePhoto : " + index);
            this.$set(this.imagesBytes, index, undefined);
            this.$set(this.imagesPath, index, undefined);
        },
         gePageStyle(){
            if (this.templateMode < 3){
                return "max-width:500px; margin: 0 auto;position:relative;margin-top:30px";
            } else {
                return "";
            }
        }


    }
};
</script>

<style lang="scss">@import "page4.scss";</style>
<template src="./page4.html"></template>