<script>
import InputValidatorsComponent from '../../../../shared-component/input-validators-component/input-validators-component.vue'
import CheckboxComponent from '../../components/checkbox-component/checkbox-component.vue'
import {FormValidatorsMixin} from '../../../../../mixins/form-validators-mixin.js'
import HereMap from '../../../../shared-component/here-map-component/here-map-component.vue'
import Const from "../../../../../model/Const.js"
import {RegionDptSortMixin} from '../../../../../mixins/region-dpt-sort-mixin.js'
import phoneIcon from '../../../../../assets/icons/0363-telephone.svg'
export default {
    name: "NewAdPage5",
    components: {
        InputValidatorsComponent,
        CheckboxComponent,
        HereMap
    },
    mixins: [FormValidatorsMixin,RegionDptSortMixin],
        props: ['templateMode'],
    data() {
        return {
            form: {
                location_region: null,
                location_dpt: null,
                latitude:null,
                longitude:null,
                telephone: '',
                hidePhoneNumber: false,
                acceptConditions:false,
            },
            isActive: false,
            numTelOk: false,
            // Displayed icon in input field, gived as props for input-validator-component
            location:null,
            gettingLocation: false,
            errorStr:null,
            region:'',
            center:{ 
                lat: 48.844972, 
                lng: 2.401959
            },
            icons: {
                phone: phoneIcon,// Displayed icon in input field, gived as props for input-validator-component
            },
        }
    },
     created()  {
        // Init CSV logic (Code in mixin)
        this.initRegionCSVTab()
    },
    methods: {
        setValidatedState(e, text){
            console.info("validation state : " + e + " , " + text.length);
            this.numTelOk = e  && text && text.length == 10;
            console.info("this.numTelOk : " + this.numTelOk);
             this.updateContinuer();
        },
         emitedCheckboxValue(nameValue) {
            
            this.form[nameValue] = this.form[nameValue]==false? true:false;
            console.info("checkboxValue = " + this.form[nameValue] + " , nameValue = " + nameValue);
            console.info(this.form.acceptConditions + "Accept conditions  " + nameValue)
            this.updateContinuer();
            
        },

        refreshMap(){
            this.$refs.map.refresh();
        },
        fireAddressSet(lat,long,PostalCode){
            this.form.latitude = lat;
            this.form.longitude = long;
            let postalCode = PostalCode;
            console.info("fireAddressSet :" + lat + ", " + long + ", " +PostalCode);
            let dpt = Const.getDepartementByPostalCode(postalCode);
            let reg = Const.getRegionByCode(dpt.region);
            console.info("locationInfo = "+ reg.text + " : " + dpt.region + " , " + dpt.value);
            this.form.location_region = dpt.region;
            this.sortDpt(this.form.location_region);
            this.form.location_dpt = dpt.value;
            this.updateContinuer();
        },
        continuer(){
            console.info("Page suivante !");
            this.form.telephone = this.$refs.phoneNumberInput.inputValue;
           
            this.$emit('pageDone', 5)
        },

        depSelected(){
             console.info("dep selected : " + this.form.location_dpt);
             this.updateContinuer();
             let deptGPS = Const.getGPSForDeptCode(this.form.location_dpt);
             this.$refs.map.setCenter({lat:deptGPS.lat,lng:deptGPS.long});
             this.form.latitude = deptGPS.lat;
             this.form.longitude = deptGPS.long;
             this.$refs.map.refresh();
        },
        updateContinuer(){
            console.log("========+++==========="+ this.form.acceptConditions + "==========++++==========")
            this.isActive = this.form.location_region && this.form.location_dpt && this.numTelOk && this.form.acceptConditions;
        },

        /*regionSelected(r){
            console.info("region selected : " + r);
            this.isActive = false;
        },*/
        
        async getLocation() {
      
            return new Promise((resolve, reject) => {

                if(!("geolocation" in navigator)) {
                reject(new Error("La géolocalisation n'est pas disponible !"));
                }

                navigator.geolocation.getCurrentPosition(pos => {
                resolve(pos);
                }, err => {
                reject(err);
                });

            });
        },

        async locateMe() {

        this.gettingLocation = true;
        try {
            this.gettingLocation = false;
            this.location = await this.getLocation();

           

            this.$refs.map.setCenter({lat:this.location.coords.latitude,lng:this.location.coords.longitude});

             this.form.latitude = this.location.coords.latitude;
             this.form.longitude = this.location.coords.longitude
            this.$refs.map.getInfosForLocation(this.location.coords.latitude,this.location.coords.longitude).then(
                (locationInfo) =>{
                    let postalCode = locationInfo.Response.View[0].Result[0].Location.Address.PostalCode;
                    let dpt = Const.getDepartementByPostalCode(postalCode);
                    let reg = Const.getRegionByCode(dpt.region);
                    console.info("locationInfo = "+ reg.text + " : " + dpt.region + " , " + dpt.value);
                    this.form.location_region = dpt.region;
                    this.sortDpt(this.form.location_region);
                    this.form.location_dpt = dpt.value;
                    this.$refs.map.refresh();
                    this.updateContinuer();
                }, (error) => {
                    console.error(error);
                }
            );
        } catch(e) {
            this.gettingLocation = false;
            this.errorStr = e.message;

        }
        
        },

        gePageStyle(){
            if (this.templateMode < 3){
                return "max-width:500px; margin: 0 auto;position:relative;";
            } else {
                return "";
            }
        }
    }
};
</script>

<style lang="scss">
@import "page5.scss";
</style>
<template src="./page5.html"></template>