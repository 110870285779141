<script>

import LoginRegisterComponent from '../../../../shared-component/login-register-component/login-register-component.vue'

export default {
    name: "NewAdPage6",
    components: {
        LoginRegisterComponent,
    },
     props: ['templateMode'],
    methods: {
        
        onSucceed(){
            this.$emit('pageDone', 6);
        },

         gePageStyle(){
            if (this.templateMode < 3){
                return "max-width:500px; margin: 0 auto;position:relative;";
            } else {
                return "";
            }
        }
    }
};
</script>

<style lang="scss">
@import "page6.scss";
</style>
<template src="./page6.html"></template>