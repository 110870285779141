<script>


import AnnoncesService from '../../../../../services/AnnoncesService';
import AuthService from '../../../../../services/AuthService';
import StorageService from '../../../../../services/StorageService';
import {Annonce} from '../../../../../model/Annonce';

export default {
    name: "NewAdPage7",
    props:['form','imagesPath','templateMode'],
    data() {
        return {
            showButton: true,
            publicationdone: false,
            publicationInProgress:false,
            errorMessage:null,
            optimizedImagesPath:null,
            publishing:false,
             // Displayed icon in input field, gived as props for input-validator-component
        }
    },
    created()  {

    },
    methods: {
        
        optimizeImagePath(){
            this.optimizedImagesPath = [];
             for(let i=0;i<this.imagesPath.length; i++){
                let file = this.imagesPath[i];
                if (file){
                    this.optimizedImagesPath.push(file);
                }
                
            }
        },

        uploadPhoto(annonce,i){
            if (i >= this.optimizedImagesPath.length){
                return Promise.resolve();
            } else {
                let file = this.optimizedImagesPath[i];
                annonce["photo"+(i+1)] = true;
                return new Promise((resolve) =>{
                    StorageService.resizeAnduploadPhoto(annonce,i+1,file,640,480).then(
                        () => {
                        // upload photo reussie
                        resolve(this.uploadPhoto(annonce,i+1));
                        });
                });
            }
        },
        

        publier(){
            console.info("publier()");
            this.publishing = true;

            this.showButton = false;
            this.publicationInProgress = true;

            this.optimizeImagePath();
 
            let annonce = new Annonce();

            annonce.titre = this.form.titre;
            annonce.desc = this.form.description;
            annonce.crit = "";
            annonce.cat = this.form.category;
            annonce.scat = this.form.subCategory;
            annonce.etat = this.form.condition;
            annonce.brand = this.form.brand;
            annonce.prix = this.form.prix;
            annonce.region = this.form.location_region;
            annonce.dept = this.form.location_dpt;
            annonce.gpslat = this.form.latitude;
            annonce.gpslng = this.form.longitude;
            annonce.fact = this.form.invoice;
            annonce.garanty = this.form.guarantee;
            annonce.photo1 = false;
            annonce.photo2 = false;
            annonce.photo3 = false;
            annonce.photo4 = false;
            annonce.tel = this.form.telephone;
            annonce.notel = this.form.hidePhoneNumber;
            annonce.nocom = this.form.envoipossible;
            annonce.userId = AuthService.getCurrentUserId();

            

            this.uploadPhoto(annonce,0).then(
                () => {
                    AnnoncesService.createAnnonce(annonce).then(() => {
                        console.info("Publication annonce reussie ! ");
                           this.$emit('pageDone', 10)
                        this.publicationInProgress = false;
                        this.publicationdone = true;
                        this.publishing = false;

                    },
                    (error) => {
                        this.errorMessage = error;
                        this.publicationInProgress = false;
                        this.publishing = false;
                    });
                },
                (error) => {
                    this.errorMessage = error;
                    this.publicationInProgress = false;
                    this.publishing = false;
                }
                );
        },

        publierUneAutreAnnonce(){
           location.reload();
        },

        gePageStyle(){
            if (this.templateMode < 3){
                return "max-width:500px; margin: 0 auto;position:relative;";
            } else {
                return "";
            }
        }
    }
};
</script>

<style lang="scss">
@import "page7.scss";
</style>
<template src="./page7.html"></template>