<script>
import InputValidatorsComponent from '../../../../shared-component/input-validators-component/input-validators-component.vue'
import CheckboxComponent from '../../components/checkbox-component/checkbox-component.vue'
import {FormValidatorsMixin} from '../../../../../mixins/form-validators-mixin.js'
import {CategoryMixin} from '../../../../../mixins/category-mixin.js'
import {BrandSortMixin} from '../../../../../mixins/brand-sort-mixin.js'
export default {
    name: "NewAdPage1",
    components: {
        InputValidatorsComponent,
        CheckboxComponent
    },
    mixins: [FormValidatorsMixin, CategoryMixin,BrandSortMixin],
    props: ['templateMode'],
    data() {
        return {
            form: {
                titre: '',
                category: null,
                subCategory: null,
                brand: null,
            },
            // Form input field, valid or not
            formValid: {
                titre: false,
            },
            // Not show ratio & brand for 'DVD' and 'Service'
            showFormDefaultInput: false,
            titreValide: false,
            isActive: false,
            categoryvalid:false,
            brandSelected:false,
        }
    },
    created()  {
        this.initCategoryCSVTab(false);
    },
    methods: {
        doWeShowBrand(){
            return (this.brand_options.length > 1) && this.categoryvalid;
        },
        isBrandValid(){
            if (this.doWeShowBrand()){
                return this.brandSelected;
            } else {
                return true;
            }
        },
        // Remove brand, guarantee & invoice field for "Livre / Dvd" & "Service" catégory
        showFormRatio() {
            if(this.form.category == "Livre / Dvd" || this.form.category == "Service" || this.form.category == null) {
                this.showFormDefaultInput = false
            }
            else {
                this.showFormDefaultInput = true
            }
        },
        continuer(){
            console.info("Click sur continuer !");

            console.info("Page suivante !");
            this.form.titre = this.$refs.titleInput.inputValue;
            this.$emit('pageDone', 1)
       
        },
        isTitreValid(){
            return this.$refs.titleInput.inputValue.length >= 2 && this.$refs.titleInput.inputValue.length <=100;
        },
        setValidatedState(e, text){
             console.info("validation state : " + e + " , " + text.length);
             this.updateContinuerState();
            

             console.info("this.isActive : " + this.isActive);
        },
        categorySelected(){
             console.info("category selected");
             this.categoryvalid = false;
             this.brandSelected = false;
             this.SelectedCategoryForBrand(this.form.category);
             this.updateContinuerState();
        },
        subCategorySelected(){
            console.info("subCategorySelected()");
            this.categoryvalid = true;
            this.$refs.titleInput.sendValidate();
            this.updateContinuerState();
        },
        brandHasBeenSelected(){
            console.info("brand selected");
            this.brandSelected = true;
            console.info("Brand has been selected : " + this.form.brand);
           this.updateContinuerState();
        },

        updateContinuerState(){
            this.isActive = this.isTitreValid() && this.categoryvalid && this.isBrandValid();
            console.info("updateContinuerState : " + this.isActive);
        },
        gePageStyle(){
            if (this.templateMode < 3){
                return "max-width:500px; margin: 0 auto;position:relative;";
            } else {
                return "";
            }
        }
    }
};
</script>

<style lang="scss">@import "page1.scss";</style>
<template src="./page1.html"></template>