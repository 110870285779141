<script>
import InputValidatorsComponent from '../../../../shared-component/input-validators-component/input-validators-component.vue'
import CheckboxComponent from '../../components/checkbox-component/checkbox-component.vue'
import {FormValidatorsMixin} from '../../../../../mixins/form-validators-mixin.js'

import Const from "../../../../../model/Const.js"


export default {
    name: "NewAdPage2",
    components: {
        InputValidatorsComponent,
        CheckboxComponent
    },
    mixins: [FormValidatorsMixin],
    props: ['templateMode'],
    data() {
        return {
            descSet:false,
            form: {
                description: "",
                 prix:0,
            },
            // Form input field, valid or not
            formValid: {

            },
            // Not show ratio & brand for 'DVD' and 'Service'
            showFormDefaultInput: false,
          
            isActive: false,
             options_condition: [
                { value: null, text: 'Etat du produit' , disabled: true},
                ...Const.getEtatsProduits()
            ],
             
             priceOk: false,
          
        }
    },
 
    methods: {

        // Remove brand, guarantee & invoice field for "Livre / Dvd" & "Service" catégory
        continuer(){
            console.info("Click sur continuer !");  
            this.form.prix = parseFloat(this.$refs.prix.inputValue);
            this.$emit('pageDone', 2)    
        },

        isDescriptionValid(){
            return this.form.description && this.form.description.length > 1 && this.form.description.length < 5000;
        },
        updateContinuerState(){
            this.isActive = this.isDescriptionValid() && this.priceOk;
        },
        descriptionChanged(){
            this.updateContinuerState();
            this.descSet = true;
        },
        
      setValidatedState(e, text){
            this.priceOk = e  && text && text.length >0;
            this.updateContinuerState();
        },
         gePageStyle(){
            if (this.templateMode < 3){
                return "max-width:500px; margin: 0 auto;position:relative;";
            } else {
                return "";
            }
        }
    }
};
</script>

<style lang="scss">@import "page2.scss";</style>
<template src="./page2.html"></template>