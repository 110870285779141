<template>
    <main class="checkbox-container">
        <b-form-checkbox 
            class="checkbox"
            :name="nameValue" 
            :checked="checkedValueOnInit"
            v-model="checkboxValue"
            @change="sendCheckboxValue($event)">
                {{checkboxDescription}}
        </b-form-checkbox>
    </main>
</template>

<script>
export default {
    name: "CheckboxComponent",
    components: {
    },
    props: ['nameValue', 'checkedValueOnInit', 'checkboxDescription'],
    data() {
        return {
            checkboxValue : this.checkedValueOnInit,
        }
    },
    methods: {
        sendCheckboxValue(eventValue) {
            this.$emit('checkboxState', eventValue, this.nameValue)
        }
    }
};
</script>

<style lang="scss">
    @import "checkbox-component.scss";
</style>