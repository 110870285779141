<script>
import InputValidatorsComponent from '../../shared-component/input-validators-component/input-validators-component.vue'
import CheckboxComponent from './components/checkbox-component/checkbox-component.vue'

import {FormValidatorsMixin} from '../../../mixins/form-validators-mixin.js'
import {ToastMixin} from '../../../mixins/toast-mixin.js'

import phoneIcon from '../../../assets/icons/0363-telephone.svg'
import Const from "../../../model/Const.js"
import NewAdPage1 from "./pages/page1/page1.vue"
import NewAdPage2 from "./pages/page2/page2.vue"
import NewAdPage3 from "./pages/page3/page3.vue"
import NewAdPage4 from "./pages/page4/page4.vue"
import NewAdPage5 from "./pages/page5/page5.vue"
import NewAdPage6 from "./pages/page6/page6.vue"
import NewAdPage7 from "./pages/page7/page7.vue"

import moneyIcon from '../../../assets/icons/0352-bag-euro.svg'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import router from '../../../router'

export default {
    name: "NewAdPage",
    components: {
        InputValidatorsComponent,
        CheckboxComponent,
        NewAdPage1,
        NewAdPage2,
        NewAdPage3,
        NewAdPage4,
        NewAdPage5,
        NewAdPage6,
        NewAdPage7,
    },
    mixins: [FormValidatorsMixin, ToastMixin],
    props: ['templateMode'],
    data() {
        return {
            pageShown : [true,false,false,false,false,false,false],
            pageAvailable : [true,false,false,false,true,false,false],
            // Form value
            form: {
                titre: '',
                category: null,
                subCategory: null,
                brand: null,
                prix: '',
                location_region: null,
                location_dpt: null,
                invoice: null,
                guarantee: null,
                telephone: '',
                hidePhoneNumber: false,
                envoipossible: false,
                condition:null,
                description:'',
                latitude:null,
                longitude:null,
                },
            imagesPath: [undefined,undefined,undefined,undefined],
            // Form input field, valid or not
            formValid: {
                titre: false,
                prix: false,
                adLocation: false,
                telephone: false
            },
            // Used to select & click on the real input file btn
            inputFileRef: null,
            // Uploaded image
            product_photo: null,
            // Uploaded images list
            preview: [],
            // Input file classes switch
            filledArray: false,
            // Option value for product state
            options_condition: [
                { value: null, text: 'Etat du produit' , disabled: true},
                ...Const.getEtatsProduits()
            ],
            // Displayed icon in input field, gived as props for input-validator-component
            icons: {
                phone: phoneIcon,// Displayed icon in input field, gived as props for input-validator-component
         
                money: moneyIcon,
       
            },
            // Not show ratio & brand for 'DVD' and 'Service'
            showFormDefaultInput: false,
            // Bellow, WIP
            description_product: '',
            selected: null,
            currentPageNumber: 0,
            terminated: false,
            produitPhysique:false,
        }
    },
    created()  {
        this.$emit('headerState', 4);
    },
    mounted() {
        // Redirect click on hidden input file
        this.inputFileRef = this.$refs.inputFile
    },
    methods: {
        closePublier(){
            console.info("closePublier");
            router.push({ path: '/'});
        },
        // Redirect click on hidden input file btn
        selectFileInput() {
            this.inputFileRef.$el.click()
        },
        // Handle file input change
        onFileChange(e) {
            for (const key in e.target.files) {
                // Prevent for in to take length as key argument
                if(typeof e.target.files[key] == 'number') {
                    return
                }

                const file = e.target.files[key];

                // If array not filled and file selected, push image in preview
                if(this.preview.length < 4 && file != undefined) {
                    this.preview.push(URL.createObjectURL(file))
                    this.filledArray = true
                }
                // If array is filled prevent user from pushing another image, once
                else if(this.preview.length == 4 && file != undefined) {
                    this.filledArray = true
                    this.popToast(this.errorMessageInputFile, 'error-toast')
                    return 
                }
                // If array is empty && file not selected change display cause there is no preview
                else if(this.preview.length == 0 && file == undefined) {
                    this.filledArray = false
                }
            }
        },
        // Handle file input deletion
        removeImage(e, imageIndex) {
            this.preview.splice(imageIndex, 1)
            
            if(this.preview.length === 0) {
                this.filledArray = false
            }
        },
        // Handle children checkbox-component value
        emitedCheckboxValue(checkboxValue, nameValue) {
            for (const key in this.form) {
                if (key == nameValue) {
                    this.form[key] = checkboxValue
                }
            }
        },
        pageDone(pageNumber){
            console.info("page done " + pageNumber);
            if (pageNumber == 10){
                this.terminated = true;
                return;
            }

            let nextPage = true;
            let nextNextPage = false;

            if (pageNumber == 1){
                this.form.titre = this.$refs["adPage"+pageNumber].form.titre;
                this.form.category = this.$refs["adPage"+pageNumber].form.category;
                this.form.subCategory = this.$refs["adPage"+pageNumber].form.subCategory;
                this.form.brand = this.$refs["adPage"+pageNumber].form.brand;
                this.produitPhysique = this.$refs["adPage"+pageNumber].doWeShowBrand();
            } else if (pageNumber == 2){
                this.form.description = this.$refs["adPage"+pageNumber].form.description;
                this.form.prix = this.$refs["adPage"+pageNumber].form.prix;
                if (!this.produitPhysique){
                    nextNextPage = true;
                    this.form.invoice = false;
                    this.form.guarantee = false;
                    this.form.condition = 1;
                    this.form.envoipossible = false;
                }
            } else if (pageNumber == 3){    
                this.form.invoice = this.$refs["adPage"+pageNumber].form.invoice;
                this.form.guarantee = this.$refs["adPage"+pageNumber].form.guarantee;
                this.form.condition = this.$refs["adPage"+pageNumber].form.condition;
                this.form.envoipossible = this.$refs["adPage"+pageNumber].form.envoipossible;
            } else if (pageNumber == 4){
                this.imagesPath = this.$refs["adPage"+pageNumber].imagesPath;
            } else if (pageNumber == 5){
                this.form.location_region = this.$refs["adPage"+pageNumber].form.location_region;
                this.form.location_dpt = this.$refs["adPage"+pageNumber].form.location_dpt;
                this.form.latitude = this.$refs["adPage"+pageNumber].form.latitude;
                this.form.longitude = this.$refs["adPage"+pageNumber].form.longitude;
                this.form.telephone = this.$refs["adPage"+pageNumber].form.telephone;
                this.form.hidePhoneNumber = this.$refs["adPage"+pageNumber].form.hidePhoneNumber;
                 if (firebase.auth().currentUser){
                    nextNextPage = true;
                }
            } 

            if (nextNextPage){
                this.$set(this.pageShown, pageNumber-1, false);
                this.$set(this.pageShown, pageNumber+1, true);
                this.currentPageNumber = pageNumber+1;
                this.pageAvailable[pageNumber] = false;
            } else if (nextPage){
                this.$set(this.pageShown, pageNumber-1, false);
                this.$set(this.pageShown, pageNumber, true);
                this.currentPageNumber = pageNumber;
            }
            this.pageAvailable[this.currentPageNumber] = true;

            console.info("titre = " + this.form.titre);
            console.info("category = " + this.form.category);
            console.info("subCategory = " + this.form.subCategory);
            console.info("brand = " + this.form.brand);
            console.info("condition = " + this.form.condition);
            console.info("description = " + this.form.description);
            console.info("prix = " + this.form.prix);
             console.info("latitude = " + this.form.latitude);
            console.info("longitude = " + this.form.longitude);
            console.info("region = " + this.form.location_region);
            console.info("dept = " + this.form.location_dpt);
            console.info("invoice = " + this.form.invoice);
            console.info("guarantee = " + this.form.guarantee);

            console.info("phone = " + this.form.telephone);
            console.info("hidePhoneNumber = " + this.form.hidePhoneNumber);
            console.info("envoipossible = " + this.form.envoipossible);
            console.info("-------------> page " + this.currentPageNumber);
            if (this.currentPageNumber == 4){
                console.info("------------->  page 5 : on refresh la map");
                
                 this.$nextTick(() => {
                        this.$refs.adPage5.refreshMap();
                    });
            } else if (this.currentPageNumber == 6){
                console.info("------------->  page 7 : on publie automatiquement");
                
                 this.$nextTick(() => {
                        this.$refs.adPage7.publier();
                    });
            }
        },
        isPageDisplayed(pageNumber){
            return this.pageShown[pageNumber-1];
        },
        retour(){
            console.info("Click sur Retour !");

            this.$set(this.pageShown, this.currentPageNumber, false);

           
            let newPageNumber = this.currentPageNumber - 1;

            while(!this.pageAvailable[newPageNumber]){
                newPageNumber--;
            }

            this.$set(this.pageShown, newPageNumber, true);
            this.currentPageNumber = newPageNumber;
        }
    }
};
</script>

<style lang="scss">@import "new-ad-page.scss";</style>
<template src="./new-ad-page.html"></template>